/* eslint-disable react/jsx-pascal-case*/
/* eslint-disable react/function-component-definition*/
/* eslint-disable import/no-unused-modules*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { Layout } from '../components/common';
import SEO from '../components/common/SEO';
import { useOpenContactModal, useViewport } from '../hooks';
import styles from './styles/techrewind.module.scss';
import ogimage from '../images/tech-rewind-2023/tech-rewind-2023-og.jpg';
import Video from '../components/ui/Video';
import introVid from '../images/tech-rewind-2023/mathieu-intro-2.mp4';
import recVid from '../images/tech-rewind-2023/new-rec-features-video-1.mp4';
import workbenchVid from '../images/tech-rewind-2023/script-writer.mp4';
import uiVid from '../images/tech-rewind-2023/automatic-ui-updates-trimmed_3.mp4';
import smartgateVid from '../images/tech-rewind-2023/felix-2.mp4';
import adVid from '../images/tech-rewind-2023/Awa4.mp4';
import logo from '../images/tech-rewind-2023/witbe_logo_white_2023.svg';
import request from '../images/tech-rewind-2023/rec-request-control.gif';
import voice from '../images/tech-rewind-2023/rec-voice-control.gif';
import smart from '../images/tech-rewind-2023/rec-smart-search.gif';
import smartgateDash from '../images/tech-rewind-2023/smartgate-dashboards.svg';
import smartgateDashMobile from '../images/tech-rewind-2023/smartgate-dashboards-wide.svg';
import kpi from '../images/tech-rewind-2023/key-performance-indicators-1.svg';
import kpiMobile from '../images/tech-rewind-2023/key-performance-indicators-wide.svg';
import outroVid from '../images/tech-rewind-2023/mathieu-outro-3.mp4';
import spanish from '../images/tech-rewind-2023/thanks/thanks-spanish.mp4';
import english from '../images/tech-rewind-2023/thanks/thanks-english.mp4';
import french from '../images/tech-rewind-2023/thanks/thanks-french.mp4';
import mandarin from '../images/tech-rewind-2023/thanks/thanks-mandarin.mp4';
import portuguese from '../images/tech-rewind-2023/thanks/thanks-portuguese.mp4';
import libanese from '../images/tech-rewind-2023/thanks/thanks-libanese.mp4';
import malagasy from '../images/tech-rewind-2023/thanks/thanks-malagasy.mp4';
import hindi from '../images/tech-rewind-2023/thanks/thanks-hindi.mp4';
import TechRewindNav from '../components/techRewind/TechRewindNav';
import { MODAL_SOURCE_BUTTONS } from '../constants/modal';

const { TECH_REWIND_23 } = MODAL_SOURCE_BUTTONS;

const anchors = [ 'overview', 'rec', 'workbench', 'smartgate', 'adMonitoring', 'next' ];

export default function TechRewind2023() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpointMobile = 834;

    const openContactModal = useOpenContactModal(TECH_REWIND_23);

    const techRewindImages = useStaticQuery(graphql`
        query TechRewindImages {
            mathieu: file(relativePath : { eq: "tech-rewind-2023/mathieu-intro-still-2.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 3840
                        placeholder: NONE
                    )
                }
            },
            rec: file(relativePath : { eq: "tech-rewind-2023/new-rec-features-laptop-1.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1281
                        placeholder: NONE
                    )
                }
            },
            smartnav: file(relativePath : { eq: "tech-rewind-2023/smartnavigate2.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1200
                        placeholder: NONE
                    )
                }
            },
            automatic: file(relativePath : { eq: "tech-rewind-2023/automatic-ui-updates-still.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        placeholder: NONE
                    )
                }
            },
            ad: file(relativePath : { eq: "tech-rewind-2023/ad-monitoring-laptop.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1396
                        placeholder: NONE
                    )
                }
            },
            outro: file(relativePath : { eq: "tech-rewind-2023/mathieu-outro-still-3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1078
                        placeholder: NONE
                    )
                }
            },
            charbel: file(relativePath : { eq: "tech-rewind-2023/charbel-placeholder.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 766
                        placeholder: NONE
                    )
                }
            },
            workbench: file(relativePath : { eq: "tech-rewind-2023/nico-still-2.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 800
                        placeholder: NONE
                    )
                }
            },
            smartgate: file(relativePath : { eq: "tech-rewind-2023/felix-still-3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 800
                        placeholder: NONE
                    )
                }
            },
            adM: file(relativePath : { eq: "tech-rewind-2023/awa-still-2.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 800
                        placeholder: NONE
                    )
                }
            },
            english: file(relativePath : { eq: "tech-rewind-2023/thanks/thanks-english.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        placeholder: NONE
                    )
                }
            },
            spanish: file(relativePath : { eq: "tech-rewind-2023/thanks/thanks-spanish.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        placeholder: NONE
                    )
                }
            },
            french: file(relativePath : { eq: "tech-rewind-2023/thanks/thanks-french.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        placeholder: NONE
                    )
                }
            },
            mandarin: file(relativePath : { eq: "tech-rewind-2023/thanks/thanks-mandarin.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        placeholder: NONE
                    )
                }
            },
            portuguese: file(relativePath : { eq: "tech-rewind-2023/thanks/thanks-portuguese.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        placeholder: NONE
                    )
                }
            },
            libanese: file(relativePath : { eq: "tech-rewind-2023/thanks/thanks-libanese.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        placeholder: NONE
                    )
                }
            },
            malagasy: file(relativePath : { eq: "tech-rewind-2023/thanks/thanks-malagasy.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        placeholder: NONE
                    )
                }
            },
            hindi: file(relativePath : { eq: "tech-rewind-2023/thanks/thanks-hindi.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        placeholder: NONE
                    )
                }
            }
        }
    `);

    const imageMain = techRewindImages.mathieu.childImageSharp.gatsbyImageData;
    const imageRec = techRewindImages.rec.childImageSharp.gatsbyImageData;
    const imageSmartNav = techRewindImages.smartnav.childImageSharp.gatsbyImageData;
    const imageUI = techRewindImages.automatic.childImageSharp.gatsbyImageData;
    const imageAd = techRewindImages.ad.childImageSharp.gatsbyImageData;
    const imageOutro = techRewindImages.outro.childImageSharp.gatsbyImageData;
    const imageCharbel = techRewindImages.charbel.childImageSharp.gatsbyImageData;
    const imageWorkbench = techRewindImages.workbench.childImageSharp.gatsbyImageData;
    const imageSmartgate = techRewindImages.smartgate.childImageSharp.gatsbyImageData;
    const imageAdMonitoring = techRewindImages.adM.childImageSharp.gatsbyImageData;
    const imageEnglish = techRewindImages.english.childImageSharp.gatsbyImageData;
    const imageSpanish = techRewindImages.spanish.childImageSharp.gatsbyImageData;
    const imageFrench = techRewindImages.french.childImageSharp.gatsbyImageData;
    const imageMandarin = techRewindImages.mandarin.childImageSharp.gatsbyImageData;
    const imagePortuguese = techRewindImages.portuguese.childImageSharp.gatsbyImageData;
    const imageLibanese = techRewindImages.libanese.childImageSharp.gatsbyImageData;
    const imageMalagasy = techRewindImages.malagasy.childImageSharp.gatsbyImageData;
    const imageHindi = techRewindImages.hindi.childImageSharp.gatsbyImageData;

    return (
        <Layout title='Tech Rewind 2023 - Witbe' variant='black'>
            <SEO
                title='Witbe 2023 Tech Rewind: Watch our newest technologies'
                ogDescription="Witbe's Tech Rewind 2023 recaps the newest features for the Remote Eye Controller, Workbench, Smartgate, and the Witbox."
                description="Witbe's Tech Rewind 2023 recaps the newest features for the Remote Eye Controller, Workbench, Smartgate, and the Witbox."
                image={ogimage}
            />
            <TechRewindNav year={2023} anchors={anchors} hideOnMobile />
            <div id='overview'>
                <div id={styles.mainImageWrapperMain} className={styles.hideNavOnMobile}>
                    <div className={styles.circleOne}>
                        <div className={styles.animateWrapperDesktop}>
                            <GatsbyImage
                                image={imageMain}
                                alt='Witbe Tech Rewind 2023 Intro'
                                className={styles.placeholderWrapper}
                            />
                            <Video controls muted video={introVid} poster='https://www.witbe.net/assets/placeholders/mathieu-intro.jpg' />
                        </div>
                        <div className={styles.introText}>
                            <img src={logo} alt='Witbe logo' className={styles.logo} />
                            <h1 className={`${styles.introTextTitle} ${styles.blue}`}>
                                {t('siteMetadata.techRewind.2023.title')}
                            </h1>
                            <p className={styles.introTextSub}>{t('siteMetadata.techRewind.2023.subtitle')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id='rec'>
                <div id={styles.secondImageWrapper}>
                    <div className={styles.circleTwo}>
                        <div className={styles.secondSectionText}>
                            <h1 className={styles.secondSectionTextTitle}>
                                {t('siteMetadata.techRewind.2023.rec')}
                            </h1>
                            <p className={styles.secondSectionTextSub}>{t('siteMetadata.techRewind.2023.recSub')}</p>
                            <p className={styles.secondSectionTextParagraph}>{t('siteMetadata.techRewind.2023.recText')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id={styles.secondImageWrapper}>
                <div className={styles.mainImageTextWrapper}>
                    <div className={styles.mainImageLeft}>
                        <div className={styles.mainImageContent}>
                            <h2 className={styles.mainImageSubheader}>
                                {t('siteMetadata.techRewind.2023.newRec')}
                            </h2>
                            <h1 className={styles.mainImageTitle}>
                                {t('siteMetadata.techRewind.2023.newRecTitle')}
                            </h1>
                            <p className={styles.mainImageSub}>{t('siteMetadata.techRewind.2023.newRecText')}</p>
                        </div>
                    </div>
                    <div className={styles.mainImageRight}>
                        <LazyLoad offset={400} className={styles.animateWrapperDesktopBlue}>
                            <GatsbyImage
                                image={imageRec}
                                alt='New REC features'
                                className={styles.placeholderWrapper}
                            />
                            <Video
                                isDecorative
                                video={recVid}
                                poster='https://www.witbe.net/assets/placeholders/new-rec-features-laptop.png'
                            />
                        </LazyLoad>
                    </div>
                </div>
            </div>
            <section id={styles.threeColSection}>
                <ul id={styles.threeColListMargin}>
                    <li className={styles.itemMargin}>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>
                                {t('siteMetadata.techRewind.2023.request')}
                            </h3>
                            <img src={request} alt='Request control gif' className={styles.gif} />
                            <p className={styles.itemParagraph}>{t('siteMetadata.techRewind.2023.requestText')}</p>
                            <p className={styles.itemParagraph}>{t('siteMetadata.techRewind.2023.requestText2')}</p>
                        </div>
                    </li>
                    <li className={styles.itemMargin}>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>
                                {t('siteMetadata.techRewind.2023.voice')}
                            </h3>
                            <img src={voice} alt='Voice control gif' className={styles.gif} />
                            <p className={styles.itemParagraph}>{t('siteMetadata.techRewind.2023.voiceText')}</p>
                            <p className={styles.itemParagraph}>{t('siteMetadata.techRewind.2023.voiceText2')}</p>
                        </div>
                    </li>
                    <li className={styles.itemMargin}>
                        <div className={styles.itemText}>
                            <h3 className={styles.itemTitle}>
                                {t('siteMetadata.techRewind.2023.smart')}
                            </h3>
                            <img src={smart} alt='Smart search gif' className={styles.gif} />
                            <p className={styles.itemParagraph}>{t('siteMetadata.techRewind.2023.smartText')}</p>
                            <p className={styles.itemParagraph}>{t('siteMetadata.techRewind.2023.smartText2')}</p>
                        </div>
                    </li>
                </ul>
            </section>
            <div id='workbench'>
                <div id={styles.secondImageWrapper}>
                    <div className={styles.circleThree}>
                        <div className={styles.thirdSectionText}>
                            <h1 className={styles.secondSectionTextTitle}>
                                {t('siteMetadata.techRewind.2023.workbench')}
                            </h1>
                            <p className={styles.secondSectionTextSub}>{t('siteMetadata.techRewind.2023.workbenchSub')}</p>
                            <p className={styles.secondSectionTextParagraph}>{t('siteMetadata.techRewind.2023.workbenchText')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <section id={styles.twoColSection}>
                <ul id={styles.twoColListMargin}>
                    <li className={styles.twoColItem}>
                        <div className={styles.twoColItemText}>
                            <h2 className={styles.twoColItemSub}>
                                {t('siteMetadata.techRewind.2023.script')}
                            </h2>
                            <h1 className={styles.twoColItemTitle}>
                                {t('siteMetadata.techRewind.2023.scriptTitle')}
                            </h1>
                            <p className={styles.twoColItemParagraph}>{t('siteMetadata.techRewind.2023.scriptText')}</p>
                            <p className={styles.twoColItemByline} dangerouslySetInnerHTML={{__html: t('siteMetadata.techRewind.2023.scriptByline')}} />
                        </div>
                    </li>
                    <LazyLoad offset={400} className={styles.twoColItemImage}>
                        <GatsbyImage
                            image={imageWorkbench}
                            alt='Workbench video'
                            className={styles.placeholderWrapper}
                        />
                        <Video controls video={workbenchVid} poster='https://www.witbe.net/assets/placeholders/workbench.jpg' />
                    </LazyLoad>
                </ul>
            </section>
            <div id={styles.secondImageWrapper}>
                <div className={styles.secondImageTextWrapper}>
                    <div className={styles.mainImageLeft}>
                        <div className={styles.mainImageContent}>
                            <h2 className={styles.mainImageSubheaderSmall}>
                                {t('siteMetadata.techRewind.2023.smartNav')}
                            </h2>
                            <h1 className={styles.mainImageTitleSmall}>
                                {t('siteMetadata.techRewind.2023.smartNavTitle')}
                            </h1>
                            <p className={styles.mainImageSub}>{t('siteMetadata.techRewind.2023.smartNavText')}</p>
                        </div>
                    </div>
                    <div className={styles.mainImageRight}>
                        <LazyLoad offset={400} className={styles.animateWrapperDesktopBlueTwo}>
                            <GatsbyImage
                                image={imageSmartNav}
                                alt='Smart Navigate'
                                className={styles.placeholderWrapper}
                            />
                        </LazyLoad>
                    </div>
                </div>
            </div>
            <div id={styles.thirdImageWrapper}>
                <div className={styles.thirdImageTextWrapper}>
                    {width < breakpointMobile ?
                        (
                            <>
                                <div className={styles.mainImageRightReversed}>
                                    <h2 className={styles.mainImageSubheaderReversed}>
                                        {t('siteMetadata.techRewind.2023.auto')}
                                    </h2>
                                    <div className={styles.animateWrapperDesktopGray}>
                                        <GatsbyImage
                                            image={imageUI}
                                            alt='Automatic updates'
                                            className={styles.placeholderWrapper}
                                        />
                                        <Video
                                            isDecorative
                                            video={uiVid}
                                            poster='https://www.witbe.net/assets/placeholders/automatic-ui-updates-still.jpg'
                                        />
                                    </div>
                                </div>
                                <div className={styles.mainImageLeftReversed}>
                                    <div className={styles.mainImageContentReversed}>
                                        <h1 className={styles.mainImageTitleSmall}>
                                            {t('siteMetadata.techRewind.2023.autoTitle')}
                                        </h1>
                                        <p className={styles.mainImageSub}>{t('siteMetadata.techRewind.2023.autoText')}</p>
                                    </div>
                                </div>
                            </>
                        )
                        :
                        (
                            <>
                                <div className={styles.mainImageLeftReversed}>
                                    <div className={styles.mainImageContentReversed}>
                                        <h1 className={styles.mainImageTitleSmall}>
                                            {t('siteMetadata.techRewind.2023.autoTitle')}
                                        </h1>
                                        <p className={styles.mainImageSub}>{t('siteMetadata.techRewind.2023.autoText')}</p>
                                    </div>
                                </div>
                                <div className={styles.mainImageRightReversed}>
                                    <h2 className={styles.mainImageSubheaderReversed}>
                                        {t('siteMetadata.techRewind.2023.auto')}
                                    </h2>
                                    <LazyLoad offset={400} className={styles.animateWrapperDesktopGray}>
                                        <GatsbyImage
                                            image={imageUI}
                                            alt='Automatic updates'
                                            className={styles.placeholderWrapper}
                                        />
                                        <Video
                                            isDecorative
                                            video={uiVid}
                                            poster='https://www.witbe.net/assets/placeholders/automatic-ui-updates-still.jpg'
                                        />
                                    </LazyLoad>
                                </div>
                            </>
                        )}
                </div>
            </div>
            <div id='smartgate'>
                <div id={styles.secondImageWrapper}>
                    <div className={styles.circleFour}>
                        <div className={styles.fourthSectionText}>
                            <h1 className={styles.fourthSectionTextTitle}>
                                {t('siteMetadata.techRewind.2023.smartgate')}
                            </h1>
                            <p className={styles.fourthSectionTextSub}>{t('siteMetadata.techRewind.2023.smartgateSub')}</p>
                            <p className={styles.fourthSectionTextParagraph}>{t('siteMetadata.techRewind.2023.smartgateText')}</p>
                            <p className={styles.fourthSectionTextParagraph}>{t('siteMetadata.techRewind.2023.smartgateText2')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <section id={styles.twoColSectionSecond}>
                <ul id={styles.twoColListMarginSecond}>
                    <LazyLoad offset={400} className={styles.twoColItemImageSecond}>
                        <GatsbyImage
                            image={imageSmartgate}
                            alt='Smartgate video'
                            className={styles.placeholderWrapper}
                        />
                        <Video controls video={smartgateVid} poster='https://www.witbe.net/assets/placeholders/smartgate.jpg' />
                    </LazyLoad>
                    <li className={styles.twoColItemSecond}>
                        <div className={styles.twoColItemTextSecond}>
                            <h2 className={styles.twoColItemSubSecond}>
                                {t('siteMetadata.techRewind.2023.smartgate2Sub')}
                            </h2>
                            <h1 className={styles.twoColItemTitleSecond}>
                                {t('siteMetadata.techRewind.2023.smartgate2Title')}
                            </h1>
                            <p className={styles.twoColItemParagraphSecond}>{t('siteMetadata.techRewind.2023.smartgate2Text')}</p>
                            <p className={styles.twoColItemBylineSecond} dangerouslySetInnerHTML={{__html: t('siteMetadata.techRewind.2023.smartgate2Byline')}} />
                        </div>
                    </li>
                </ul>
            </section>
            <section id={styles.twoColSectionGray}>
                <ul id={styles.twoColListMarginGray}>
                    <li className={styles.twoColItemGray}>
                        <div className={styles.twoColItemTextGray}>
                            <div className={styles.twoColItemTextGrayContent}>
                                <h2 className={styles.twoColItemSubGray}>
                                    {t('siteMetadata.techRewind.2023.smartgateDash')}
                                </h2>
                                <p className={styles.itemParagraphGray}>
                                    {t('siteMetadata.techRewind.2023.smartgateDashText')}
                                </p>
                            </div>
                        </div>
                        <div className={styles.twoColItemTextGrayImage}>
                            {width < breakpointMobile ?
                                <img src={smartgateDashMobile} alt='Smartgate dashboards' />
                                :
                                <img src={smartgateDash} alt='Smartgate dashboards' />
                            }
                        </div>
                    </li>
                    <li className={styles.twoColItemGray}>
                        <div className={styles.twoColItemTextGrayLarge}>
                            <div className={styles.twoColItemTextGrayContentLarge}>
                                <h2 className={styles.twoColItemSubGray}>
                                    {t('siteMetadata.techRewind.2023.KPI')}
                                </h2>
                                <p className={styles.itemParagraphGray}>
                                    {t('siteMetadata.techRewind.2023.KPIText')}
                                </p>
                            </div>
                        </div>
                        <div className={styles.twoColItemTextGrayImageLarge}>
                            {width < breakpointMobile ?
                                <img src={kpiMobile} alt='KPI' />
                            :
                                <img src={kpi} alt='KPI' />
                            }
                        </div>
                    </li>
                </ul>
            </section>
            <div id='adMonitoring'>
                <div id={styles.secondImageWrapper}>
                    <div className={styles.circleFour}>
                        <div className={styles.fourthSectionText}>
                            <h1 className={styles.fourthSectionTextTitle}>
                                {t('siteMetadata.techRewind.2023.ad')}
                            </h1>
                            <p className={styles.fourthSectionTextSub}>{t('siteMetadata.techRewind.2023.adSub')}</p>
                            <p className={styles.fourthSectionTextParagraph}>{t('siteMetadata.techRewind.2023.adText')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <section id={styles.twoColSectionSecond}>
                <ul id={styles.twoColListMarginSecond}>
                    <LazyLoad offset={400} className={styles.twoColItemImageSecond}>
                        <GatsbyImage
                            image={imageAdMonitoring}
                            alt='Ad monitoring video'
                            className={styles.placeholderWrapper}
                        />
                        <Video controls video={adVid} poster='https://www.witbe.net/assets/placeholders/ad-monitoring.jpg' />
                    </LazyLoad>
                    <li className={styles.twoColItemSecondGray}>
                        <div className={styles.twoColItemTextSecond}>
                            <h2 className={styles.twoColItemSubSecond}>
                                {t('siteMetadata.techRewind.2023.ad2Sub')}
                            </h2>
                            <h1 className={styles.twoColItemTitleSecond}>
                                {t('siteMetadata.techRewind.2023.ad2Title')}
                            </h1>
                            <p className={styles.twoColItemParagraphSecond}>{t('siteMetadata.techRewind.2023.ad2Text')}</p>
                            <p className={styles.twoColItemBylineSecond} dangerouslySetInnerHTML={{__html: t('siteMetadata.techRewind.2023.ad2Byline')}} />
                        </div>
                    </li>
                </ul>
            </section>
            <div id={styles.secondImageWrapper}>
                <div className={styles.secondImageTextWrapper}>
                    <div className={styles.mainImageLeft}>
                        <div className={styles.mainImageContent}>
                            <h2 className={styles.mainImageSubheaderSmallSecond}>
                                {t('siteMetadata.techRewind.2023.adMatching')}
                            </h2>
                            <h1 className={styles.mainImageTitleSmall}>
                                {t('siteMetadata.techRewind.2023.adMatchingTitle')}
                            </h1>
                            <p className={styles.mainImageSub}>{t('siteMetadata.techRewind.2023.adMatchingText')}</p>
                        </div>
                    </div>
                    <div className={styles.mainImageRight}>
                        <LazyLoad offset={400} className={styles.animateWrapperDesktopBlueTwo}>
                            <GatsbyImage
                                image={imageAd}
                                alt='Ad monitoring and matching'
                                className={styles.placeholderWrapper}
                            />
                        </LazyLoad>
                    </div>
                </div>
            </div>
            <div id='next'>
                <div className={styles.circleFive} />
            </div>
            <div className={styles.outro}>
                <LazyLoad offset={400} className={styles.animateWrapperDesktopOutro}>
                    <GatsbyImage
                        image={imageOutro}
                        alt='Witbe Tech Rewind 2023 Intro'
                        className={styles.placeholderWrapper}
                    />
                    <Video controls video={outroVid} />
                </LazyLoad>
                <div className={styles.outroText}>
                    <h1 className={styles.outroTextTitle}>
                        {t('siteMetadata.techRewind.2023.outro')}
                    </h1>
                    <p className={styles.introTextSub}>{t('siteMetadata.techRewind.2023.outroSub')}</p>
                </div>
            </div>
            <div className={styles.circleSix} />
            <div className={styles.thanks}>
                <h2 className={styles.thanksTitle}>
                    {t('siteMetadata.techRewind.2023.thanks')}
                </h2>
                {width < breakpointMobile ?
                    (
                        <div className={styles.thanksGrid}>
                            <li className={styles.animateWrapperDesktopThanks}>
                                <GatsbyImage
                                        image={imageEnglish}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                <Video controls video={english} poster='https://www.witbe.net/assets/placeholders/english.jpg' />
                            </li>
                            <li className={styles.animateWrapperDesktopThanks}>
                                <GatsbyImage
                                        image={imageSpanish}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                <Video controls video={spanish} poster='https://www.witbe.net/assets/placeholders/spanish.jpg' />
                            </li>
                            <li className={styles.animateWrapperDesktopThanks}>
                                <GatsbyImage
                                        image={imageFrench}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                <Video controls video={french} poster='https://www.witbe.net/assets/placeholders/french.jpg' />
                            </li>
                            <li className={styles.animateWrapperDesktopThanks}>
                                <GatsbyImage
                                        image={imageMandarin}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                <Video controls video={mandarin} poster='https://www.witbe.net/assets/placeholders/chinese.jpg' />
                            </li>
                            <li className={styles.animateWrapperDesktopThanks}>
                                <GatsbyImage
                                        image={imagePortuguese}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                <Video controls video={portuguese} poster='https://www.witbe.net/assets/placeholders/portuguese.jpg' />
                            </li>
                            <li className={styles.animateWrapperDesktopThanks}>
                                <GatsbyImage
                                        image={imageLibanese}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                <Video controls video={libanese} poster='https://www.witbe.net/assets/placeholders/libanese.jpg' />
                            </li>
                            <li className={styles.animateWrapperDesktopThanks}>
                                <GatsbyImage
                                        image={imageMalagasy}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                <Video controls video={malagasy} poster='https://www.witbe.net/assets/placeholders/malagasy.jpg' />
                            </li>
                            <li className={styles.animateWrapperDesktopThanks}>
                                <GatsbyImage
                                        image={imageHindi}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                <Video controls video={hindi} poster='https://www.witbe.net/assets/placeholders/hindi.jpg' />
                            </li>
                        </div>
                    )
                    :
                    (
                        <div className={styles.thanksGrid}>
                            <div className={styles.thanksGridRowOne}>
                                <li className={styles.animateWrapperDesktopThanks}>
                                    <GatsbyImage
                                        image={imageEnglish}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                    <Video controls video={english} poster='https://www.witbe.net/assets/placeholders/english.jpg' />
                                </li>
                                <li className={styles.animateWrapperDesktopThanks}>
                                    <GatsbyImage
                                        image={imageSpanish}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                    <Video controls video={spanish} poster='https://www.witbe.net/assets/placeholders/spanish.jpg' />
                                </li>
                                <li className={styles.animateWrapperDesktopThanks}>
                                    <GatsbyImage
                                        image={imageFrench}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                    <Video controls video={french} poster='https://www.witbe.net/assets/placeholders/french.jpg' />
                                </li>
                                <li className={styles.animateWrapperDesktopThanks}>
                                    <GatsbyImage
                                        image={imageMandarin}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                    <Video controls video={mandarin} poster='https://www.witbe.net/assets/placeholders/chinese.jpg' />
                                </li>
                            </div>
                            <div className={styles.thanksGridRowTwo}>
                                <li className={styles.animateWrapperDesktopThanks}>
                                    <GatsbyImage
                                        image={imagePortuguese}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                    <Video controls video={portuguese} poster='https://www.witbe.net/assets/placeholders/portuguese.jpg' />
                                </li>
                                <li className={styles.animateWrapperDesktopThanks}>
                                    <GatsbyImage
                                        image={imageLibanese}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                    <Video controls video={libanese} poster='https://www.witbe.net/assets/placeholders/libanese.jpg' />
                                </li>
                                <li className={styles.animateWrapperDesktopThanks}>
                                    <GatsbyImage
                                        image={imageMalagasy}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                    <Video controls video={malagasy} poster='https://www.witbe.net/assets/placeholders/malagasy.jpg' />
                                </li>
                                <li className={styles.animateWrapperDesktopThanks}>
                                    <GatsbyImage
                                        image={imageHindi}
                                        alt='Thank you'
                                        className={styles.placeholderWrapper}
                                    />
                                    <Video controls video={hindi} poster='https://www.witbe.net/assets/placeholders/hindi.jpg' />
                                </li>
                            </div>
                        </div>
                    )
                }
            </div>
            <div id={styles.ctaTextWrapper}>
                <div className={styles.ctaContent}>
                    <h1 className={styles.ctaTitle}>
                        {t('siteMetadata.techRewind.2023.learnMore')}
                    </h1>
                    <div className={styles.learnMoreBuyButtons}>
                        <Link
                            to='/tech-rewind-2023/'
                            className={styles.blueButton}
                            onClick={openContactModal}
                        >
                            {t('siteMetadata.techRewind.2023.demo')}
                        </Link>
                        <div className={styles.cardLearnMoreInlineContainer}>
                            <Link
                                to='/technology/'
                                className={styles.cardLearnMoreInlineTablets}
                            >
                                {t('siteMetadata.techRewind.2023.schedule')}
                            </Link>
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={styles.chevronRight}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
